import { useEffect } from 'react'
import gemini from './assets/img/gemini.svg'

const Gemini = ({avatar}) => {

  useEffect(() => {
    // Select the editable element
    const editableElement = document.getElementById('editableElement');
    
    // Focus on the editable element when the page loads
    window.onload = function() {
      editableElement.focus();
    };
  }, [])
  return (
    <>
      <div className="user-msg">
        <div>
          <img className='user-logo' src={avatar} alt="chatgpt logo" />
        </div>
        <div className='user-details'>
          <p className='ai-name'>You</p>
          <p className='msg' contentEditable="true" autoFocus id="editableElement">
            tell me a joke
          </p>
        </div>
        </div>
      <div className="ai-msg">
        <div>
          <img className='logo' src={gemini} alt="gemini logo" />
        </div>
        <div className='ai-details'>
          {/* <p className='ai-name'>ChatGPT</p> */}
          <p className='msg' contentEditable="true">
            vegeta
          </p>
        </div>
      </div>
    </>
  )
}

export default Gemini
