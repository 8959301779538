import React from 'react';
import './Hero.css'; // Import the CSS file for styling

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Fake AI Screenshots</h1>
        <p className="hero-description">For Fun, Pranks & Shitposting</p>        
      </div>
    </div>
  );
};

export default Hero;